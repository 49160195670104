const texts = {
    rcActivities: 'Red Cross / Red Crescent Activities',
    langSelectLabel: 'Language: {currentLanguage}',

    headerAppName: 'IFRC GO',
    headerBetaNote: 'This is the beta version of IFRC GO. For issues and feedback on the platform, contact {email}',
    headerCloseLabel: 'close',
    headerMenuResources: 'Resources',
    headerCreateAReportLabel: 'Create a Report',
    headerDropdownNewFieldReport: 'New Field Report',
    headerDropdownCovid19IndicatorTracking: 'COVID-19 Indicator Tracking',
    headerDropdownCovid19NSFinancialOverview: 'COVID-19 NS Financial Overview',
    headerMenuHome: 'Home',
    headerMenuEmergencies: 'Emergencies',
    headerMenuSurge: 'Surge',
    headerMenuPreparedness: 'Preparedness',
    headerSearchLabel: 'Search',
    headerVisitPageTooltip: 'Visit page',
    headerLogoAltText: 'IFRC GO logo',
    headerMenuResourceTooltip: 'Visit Resources page',
    headerCreateFieldReportTooltip: 'Create Field Report',
    headerMenuHomeTooltip: 'Visit Home page',
    headerMenuEmergenciesTooltip: 'Visit Emergencies page',
    headerMenuDeploymentsTooltip: 'Visit Deployments page',
    headerMenuPreparednessTooltip: 'Visit Preparedness page',
    headerSearchPlaceholder: 'Search',

    menuHome: 'Home',
    menuEmergencies: 'Emergencies',
    menuDeployments: 'Deployments',
    menuPreparedness: 'Preparedness',
    menuRegions: 'Regions',

    globalStartDate: 'Start Date',
    globalEndDate: 'End Date',

    aboutTitle: 'IFRC Go - About',
    aboutResources: 'Resources',
    aboutDescription: 'GO is a Red Cross Red Crescent platform to connect information on emergency needs with the right response.',
    aboutUserGuidance: 'Go user guidance',
    aboutUserAdministrativeGuide: 'GO User and Administrative Guides',
    aboutUserAdministrativeGuideDescription: 'User guides help to explain the different functions and features of GO. Administrator guides include also back-end functions aimed at people who are helping to manage the site.',
    aboutReferenceMaterials: 'GO Reference Materials',
    aboutReferenceMaterialsDescription: 'These materials provide more information on how and why GO does what it does.',
    aboutVisualGuidelines: 'Visual Guidelines',
    aboutVisualIdentity: 'Visual Identity',
    aboutUserStudy: 'User Studies',
    aboutInformationArchitecture: 'Information Architecture',
    about3WGuidance: '3w guidance',
    about3WLessonLearned: '3w lessons learned',
    aboutPresentation: 'GO presentation materials',
    aboutImStrategy: 'IFRC IM strategy',
    aboutIfrcResources: 'IFRC Resources',
    aboutSurgeEvents: 'Global Surge events and trainings',
    aboutSurgeServices: 'IFRC Surge Services',
    aboutSurgeCatalogue: 'Catalogue of surge services',
    aboutSurgeNetwork: 'Surge IM network',
    aboutCommunitySurveillance: 'Community Based Surveillance',
    aboutGuidanceMaterial: 'IFRC Guidance Material',
    aboutCashHub: 'Cash Hub',
    aboutCommunityHub: 'Community Engagement Hub',
    aboutDisasterPreparednessCenter: 'Global Disaster Preparedness Centre',
    aboutDataPlaybook: 'Data Playbook',
    aboutOtherResources: 'Other IFRC Resources',
    aboutReferenceCenters: 'Reference Centres',
    aboutMissingMaps: 'Missing Maps',
    aboutReportingSystem: 'Fed-wide Data and Reporting System',
    aboutLearningPlatform: 'Learning Platform',
    aboutContacts: 'Go Contacts',
    aboutFurtherInfo: 'For any further information, please contact',
    aboutAfricaRegion: 'Africa Region',
    aboutAmericaRegion: 'Americas Region',
    aboutAsiaPacificRegion: 'Asia Pacific Region',
    aboutEuropeRegion: 'Europe Region',
    aboutMenaRegion: 'MENA Region',
    aboutGoFunding: 'GO has received dedicated personnel and funding support from:',

    registerTitle: 'IFRC Go - Register',
    registerHeading: 'Register',
    registerSubHeader: 'Staff, members and volunteers of the Red Cross Red Crescent Movement (National Societies, the IFRC and the ICRC) are welcome to register for a user account on GO, to access information for the Membership. Other responders and members of the public may browse the public areas of the site without registering for an account.',
    registerFirstName: 'First Name *',
    registerLastName: 'Last Name *',
    registerEmail: 'Email *',
    registerUsername: 'Username *',
    registerUsernameInfo: 'This will be your username to log into the platform',
    registerCountry: 'Country *',
    registerCity: 'City *',
    registerOrganizationType: 'Organization Type *',
    registerOrganizationName: 'Organization Name *',
    registerOfficeName: 'Office Name *',
    registerDepartment: 'Department',
    registerPosition: 'Position',
    registerPhoneNumber: 'Phone Number',
    registerPassword: 'Password *',
    registerConfirmPassword: 'Confirm Password *',
    registerContactRequest: 'It appears you do not have an official Red Cross Red Crescent email, we will need to verify your status. Please provide the names and email addresses of two RCRC members with active GO accounts for us to contact.',
    registerContactName: 'Contact name',
    registerContactEmail: 'Contact email',
    registerRequestAccess: 'Request Access',
    registerSubmit: 'Register',
    registerSubmitError: 'There are errors in the form. Please correct them before submitting again.',
    registerAccountPresent: 'Already have an account?',
    registerGotoLogin: 'Go to login page',
    registerLogin: 'Login',

    loginTitle: 'IFRC Go - Login',
    loginHeader: 'Login',
    loginSubHeader: 'If you are staff, member or volunteer of the Red Cross Red Crescent Movement (National Societies, the IFRC and the ICRC) login with you email and password.',
    loginUsername: 'Username',
    loginEmailUsername: 'Email/Username',
    loginPassword: 'Password',
    loginRecoverTitle: 'Recover password',
    loginForgotPassword: 'I forgot my password.',
    loginShowUsernameTitle: 'Show me my username',
    loginForgotUsername: 'I forgot my username only.',
    loginResendValidation: 'Re-send validation email.',
    loginResendValidationTitle: 'I didn\'t get my validation email',
    loginForgotUserPass: 'Forgot your password/username?',
    loginInvalid: 'Invalid username or password',
    loginErrorMessage: 'Error: {message}',
    loginButton: 'Login',
    loginDontHaveAccount: 'Don’t have an account? ',
    loginCreateAccountTitle: 'Create new account',
    loginSignUp: 'Sign up',
    loginKeep: 'Keep me logged in',

    accountUserGreeting: 'Hello {user}',


    emergenciesTitle: 'IFRC Go - Emergencies',
    emergenciesTableTitle: 'Emergencies in the last 30 days',

    fieldReportsTableTitle: 'Field Reports in the last 30 days',
    preparednessTitle: 'IFRC Go - Global Preparedness',
    preparednessHeading: 'Preparedness for Effective Response (PER)',
    preparednessDescription: 'To enable National Societies to fulfil their auxiliary role, in line with the Red Cross and Red Crescent Fundamental Principles, by strengthening local preparedness capacities to ensure timely and effective humanitarian assistance to prevent and alleviate human suffering.',

    homeTitle: 'IFRC Go - Home',
    homeHeading: 'IFRC Disaster Response and Preparedness',
    homeDescription: 'IFRC GO aims to make all disaster information universally accessible and useful to IFRC responders for better decision making.',
    homeSurgeNotification: 'Open Surge Positions',

    regionAllCountries: 'All countries',
    regionTitle: 'IFRC Go - Region',
    regionTitleSelected: 'IFRC Go - {regionName}',
    regionAppealsTableTitle: 'Active IFRC Operations',
    regionAppealsTableViewAllText: 'View all IFRC operations for {regionName} region',
    regionStatistics: 'Statistics',
    regionRecentEmergencies: 'Recent Emergencies',
    regionEmergenciesTableViewAllText: 'View all Emergencies for {regionName} region',
    region3WTitle: '3W',
    regionKeyFigures: 'Key Figures',
    regionGraphics: 'Graphics',
    regionLinks: 'Links',
    regionContacts: 'Contacts',
    regionOperationsTab: 'Operations',
    region3WTab: '3w',
    regionProfileTab: 'Regional Profile',
    regionPreparednessTab: 'Preparedness',
    regionAdditionalInfoTab: 'Additional Data',
    regionSnippetsTitle: 'Regional Content',
    regionIframeBackLink: 'Back to Regional Profile',

    regionalTabBox1: 'National Societies in {regionName}',
    regionalTabBox2: 'National Country Clusters',
    regionalTabBoxSource: 'Source: IFRC',

    alertTableAlertTypeFact: 'FACT',
    alertTableAlertTypeSims: 'SIMS',
    alertTableAlertTypeEru: 'ERU',
    alertTableAlertTypeDheops: 'DHEOps',
    alertTableAlertTypeHeops: 'HEOps',
    alertTableAlertTypeSurge: 'SURGE',
    alertTableAlertTypeRapidResponse: 'Rapid Response',
    alertTableCategoryInfo: 'Info',
    alertTableCateogryDeployment: 'Deployment',
    alertTableCategoryAlert: 'Alert',
    alertTableCategoryShelter: 'Shelter',
    alertTableCategoryStandDown: 'Stand down',
    alertTableTitle: 'Latest Alerts',
    alertTableError: 'Surge alerts not available.',
    alertTableDate: 'Date',
    alertTableCategory: 'Category',
    alertTableEmergency: 'Emergency',
    alertTableMessage: 'Alert Message',
    alertTableType: 'Type',
    alertTableViewEmergency: 'View Emergency page',
    alertTableViewAllText: 'View all surge alerts',

    highlightedOperationsViewAll: 'View all operations',
    highlightedOperationsTitle: 'Highlighted Operations',
    operationCardLastUpdated: 'Last updated:',
    operationCardTargetedPopulation: 'Targeted Population',
    operationCardDepoloyedUnits: 'Deployed Emergency Response Units',
    operationCardFunding: 'Funding Requirements',
    operationCardDeployed: 'Deployed Surge Personnel',
    operationCardFundingCoverage: 'Funding Coverage',
    operationCardFollow: 'Follow',
    operationCardFollowing: 'Following',

    countryPageTitle: 'IFRC Go - {countryName}',
    countryTitle: 'IFRC Go - Country',
    countryListInRegion: ' Countries in this Region',
    countryListViewActiveOnly: 'View only active operations',
    countryListActiveOperation: 'IFRC-Supported Operation',
    countryListActiveOperations: 'IFRC-Supported Operations',
    countryPreparednessTitle: 'Preparedness',

    countryOperationsTab: 'Operations',
    country3WTab: '3W',
    countryPreparednessTab: 'Preparedness',
    countryAdditionalInfoTab: 'Additional Data',

    emergenciesTableError: 'Latest emergencies not available.',
    emergenciesTableDate: 'Start Date',
    emergenciesTableName: 'Name',
    emergenciesTableDisasterType: 'Disaster Type',
    emergenciesTableGlide: 'Glide',
    emergenciesTableRequestedAmt: 'Requested Amount',
    emergenciesTableAffected: '# Affected',
    emergenciesTableCountry: 'Countries',
    emergenciesTableViewAll: 'View all emergencies',
    emergenciesTableRecentEmergencies: 'Recent Emergencies',
    emergenciesRecentViewAll: 'View All Emergencies For',

    keyFiguresTitle: 'Key Figures',
    contactsTitle: 'Contacts',
    contactsHeaderName: 'Name',
    contactsHeaderTitle: 'Title',
    contactsHeaderType: 'Type',
    contactsHeaderContact: 'Contact',
    contactsEmailTitle: 'Contact',
    snippetsTitle: 'Additional Graphics',
    linksTitle: 'Additional Links',

    recoverAccountTitle: 'IFRC Go - Recover Password',
    recoverAccountHeading: 'Recover Account',
    recoverAccountEmail: 'Enter the email you used during registration',
    recoverAccountEmailLabel: 'Email',
    recoverAccountSubmitText: 'Recover',

    recoverUsernameTitle: 'IFRC Go - Recover Username',
    recoverUsernameHeading: 'Getting the given Username',
    recoverUsernameEmail: 'Enter the email you used during registration',
    recoverUsernameEmailLabel: 'Email',
    recoverUsernameSubmitText: 'Send me my username',

    resendValidationTitle: 'IFRC Go - Re-send Validation Email',
    resendValidationUsername: 'Enter the email/username you used during registration',
    resendValidationUsernameLabel: 'Username',
    resendValidationSubmitText: 'Re-send',

    presentationDashAppealsTitle: 'Active Operations',

    keyFiguresError: 'Data not available.',
    keyFiguresHeading: 'IFRC Disaster Response and Preparedness',
    keyFiguresStatsOverall: 'Overall stats',
    keyFiguresActiveDrefs: 'Active DREF Operations',
    keyFiguresActiveAppeals: 'Active Emergency Appeals',
    keyFiguresBudget: 'Funding requirements (CHF)',
    keyFiguresAppealsFunding: 'Funding coverage',
    keyFiguresTargetPop: 'Targeted population',
    keyFiguresDrefTitle: 'DREF',
    keyFiguresDrefDescription: 'These are small to medium scale emergency operations funded through the Disaster Relief Emergency Fund (DREF).The DREF provides immediate financial support to National Red Cross and Red Crescent Societies, enabling them to carry out their unique role as first responders after a disaster.',
    keyFigureActiveAppealTitle: 'Emergency Appeal',
    keyFigureActiveAppealDescription: 'These are medium to large scale emergency operations funded through a public appeal for funds.',
    appealsTableTitle: 'Operations Overview',
    appealsTableError: 'Operations data not available.',
    appealsTableStartDate: 'Start Date',
    appealsTableType: 'Type',
    appealsTableCode: 'Code',
    appealsTableOperation: 'Operation',
    appealsTableDisastertype: 'Disaster Type',
    appealsTableRequestedAmount: 'Requested Amount',
    appealsTableFundedAmount: 'Funding',
    appealsTableCountry: 'Country',
    appealsTableRowEvent: 'View Emergency',
    appealsTableViewCountry: 'View Country',
    viewAllOperations: 'View all operations',

    timeLineChartAppeal: 'Appeals',
    timeLineChartAmount: 'Amount Funded Appeals',
    timeLineChartPeopleTargeted: 'People Targeted',
    timeLineChartDREFs: 'DREFs',
    timeLineChartDrefsAmount: 'Amount Funded DREFs',
    timeLineChartDrefsPeopleTargeted: 'People Targeted',
    timeLineChartError: 'Operations data not available.',
    timeLineChartByMonthTitle: 'DREFS and Appeals Over the Last Year',
    timeLineChartByYearError: 'Annual statistics not available.',
    timeLineChartByYearTitle: 'DREFS and Appeals by Year',
    timeLineChartAggregateError: 'Aggregate data not available.',
    timeLineChartHeading: 'DREFS and Appeals over time',

    regional3WMovementActivity: 'Movement activities',
    regional3WNationalActivity: 'National society activities',
    regional3WSankeyEmpty: 'Not enough data to show the chart',
    regional3WAdd: 'Add',
    budgetOverviewNSOngoingActivity: 'NS with ongoing activities',
    butgetOverviewTotalBudget: 'Total budget',
    peopleOverviewTotalPeople: 'Total number of people reached',
    peopleOverviewTargetedPeople: 'Targeted',
    statusOverviewTotalActivityStatus: 'Total activities by status',
    statusOverviewTotalActivity: 'Total activities',
    exportButtonExporting: 'Exporting...',
    exportButtonExport: 'Export',
    movementFilterOperationType: 'Operation type',
    movementFilterOperationPlaceholder: 'All Operation Types',
    movementFilterProgrammeType: 'Programme type',
    movementFilterProgrammePlaceholder: 'All Programme Types',
    movementFilterSector: 'Sectors of Activity',
    movementFilterSectorPlaceholder: 'All Sectors',
    movementFilterTag: 'Tag',
    movementFilterTagPlaceholder: 'All Tags',
    movementFilterStatus: 'Status',
    movementFilterStatusPlaceholder: 'All Status',
    countryTableDate: 'Start Date',
    countryTableName: 'Name',
    countryTableEmergency: 'Emergency',
    countryTableDisasterType: 'Disaster Type',
    countryTableRequestAmount: 'Requested Amount',
    countryTableFundedAmount: 'Funding (CHF)',
    countryTableActive: 'Active',
    countryTableReportingNS: 'Reporting NS',
    countryTablePrimarySector: 'Activity sector',
    countryTableStatus: 'Status',
    countryTableProgrammeType: 'Type',
    countryTableTargetTotal: 'Total people targeted',
    countryTableReachedTotal: 'Total people reached',
    countryTableBudgetTotal: 'Total budget',
    countryTableProjectCount: '{projectCount} projects',
    countryEditCountry: 'Edit Country',
    countryInformScore: 'Inform Score: ',
    nsActivityReporting: 'Reporting NS',
    countryPreparednessPhaseOutcomes: 'PER related uploaded documents',
    nsActivityReportingPlaceholder: 'Select Who (Reporting NS)',
    nsActivitySector: 'Activity',
    nsActivitySectorPlaceholder: 'Select What (Sector)',
    nsActivityCountry: 'Receiving NS',
    nsActivityCountryPlaceholder: 'Select Where (Country)',
    activityDetailsPlanned: 'planned',
    activityDetailsOngoing: 'ongoing',
    activityDetailsCompleted: 'completed',
    activityDetailsActiveNationalCount: 'Active National Societies',
    supportingNSListProjects: '{noOfProjects} projects',

    preparednessHeaderTitle: 'The PER approach',
    preparednessHeaderDetail: 'The PER Approach is a continuous and flexible process that enables National Societies to assess, measure and analyse the strengths and gaps of its preparedness and response mechanism, and ultimately take necessary action to improve it. For more information on the PER Approach select {link}.',
    preparednessMapError: 'Data not available.',
    preparednessMapDownloadTitle: 'Preparedness state',
    preparednessOverviewCrumb: 'PER Overview',
    preparednessOverview: 'Preparedness Overview',
    preparednessSummary: 'Preparedness Summary',
    preparednessColumnBar: 'Preparedness Column Bar',
    preparednessWorkPlan: 'Preparedness Work Plan',
    preparednessPhaseOutcomes: 'Preparedness Phase Outcomes',

    prepGlobalTrendsTab: 'Global Summary',
    prepGlobalPerformanceTab: 'Global Performance',
    prepResourceCatalogueTab: 'Catalogue of Resources',
    prepOpLearningTab: 'Operational Learning',

    emergenciesLeftMenuHeading: 'Operations by Type',
    emergenciesLeftMenuTitle: 'IFRC Emergency Operations',
    perPhaseDropdownAllPerPhases: 'All PER Phases',
    perPhaseDropdownOrientation: 'Orientation',
    perPhaseDropdownAssessment: 'Assessment',
    perPhaseDropdownPrioritization: 'Prioritization',
    perPhaseDropdownPlan: 'Plan of action',
    perPhaseDropdownAction: 'Action & Accountability',
    perTypeDropdownAllPerType: 'All PER Types',
    explanationBubbleCurrentPerPhase: 'Current PER phase',
    explanationBubbleOrientation: 'Orientation',
    explanationBubbleAssessment: 'Assessment',
    explanationBubblePrioritization: 'Prioritization & Analysis',
    explanationBubbleWorkPlan: 'Work Plan',
    explanationBubbleAction: 'Action & Accountability',
    explanationBubbleIncomplete: 'Incomplete',
    operationsPopoverClose: 'Close popover',
    operationsPopoverDismiss: 'Dismiss',
    operationsPopoverCurrentPerPhase: 'Current PER process phase',
    operationsPopoverCurrentPerType: 'Current PER process type',
    operationsPopoverOrientation: 'Orientation',
    operationsPopoverAssessment: 'Assessment',
    operationsPopoverPrioritization: 'Prioritization',
    operationsPopoverPlan: 'Plan of action',
    operationsPopoverAction: 'Action & Accountability',
    operationsPopoverNoData: 'No data',
    operationsPopoverDate: 'Date of the assessment',
    nationalSocietiesCountries: '({value} countries out of {total})',
    nationalSocietiesTitle: 'National Societies engaged in the PER process',
    globalPreparednessTitle: 'Global Preparedness Highlights',
    globalPreparednessHighPerforming: 'High Performing Components (globally)',
    globalPreparednessTopPrioritized: 'Top Prioritized Components (globally)',
    globalPreparednessPermission: 'You can only see this data if you have the correct permissions.',
    contactPerTitle: 'Contact PER team',
    contactPerDetails: 'Click on the button to contact PER team if you have any questions regarding the PER process.',
    mobileHeaderVisitPage: 'Visit page',
    mobileHeaderTitle: 'IFRC GO',
    mobileHeaderSearchTitle: 'Search',
    mobileHeaderSearch: 'Search...',
    mobileHeaderCreateFieldReport: 'Create Field Report',
    mobileHeaderClose: 'close',
    mobileHeaderVisitHome: 'Visit Home page',
    mobileHeaderHome: 'Home',
    mobileHeaderVisitEmergencies: 'Visit emergencies page',
    mobileHeaderEmergencies: 'Emergencies',
    mobileHeaderRegion: 'Region',
    mobileHeaderVisitAfrica: 'Visit Africa region page',
    mobileHeaderAfrica: 'Africa',
    mobileHeaderVisitAmerica: 'Visit Americas region page',
    mobileHeaderAmerica: 'Americas',
    mobileHeaderVisitAsia: 'Visit Asia Pacific region page',
    mobileHeaderAsia: 'Asia Pacific',
    mobileHeaderVisitEurope: 'Visit Europe region page',
    mobileHeaderEurope: 'Europe',
    mobileHeaderVisitMiddleEast: 'Visit Middle East & North Africa region page',
    mobileHeaderMiddleEast: 'Middle East & North Africa',
    mobileHeaderVisitDeployments: 'Visit Deployments page',
    mobileHeaderDeployments: 'Deployments',
    mobileHeaderVisitResources: 'Visit Resources page',
    mobileHeaderResources: 'Resources',
    mobileHeaderVisitAccount: 'View user account',
    mobileHeaderAccount: 'Account',
    userMenuAccess: 'Access user menu',
    userMenuViewAccount: 'View user account',
    userMenuAccount: 'Account',
    userMenuLogout: 'Logout',
    userMenuLogin: 'Login',
    userMenuRegister: 'Register',

    tableReport: 'Field Reports',
    tableOperation: 'Operations',
    tableEmergency: 'Emergencies',
    tableAlert: 'Surge Alerts',
    tableEru: 'Deployed ERUs',
    tablePersonnel: 'Deployed Personnel',
    tableTitle: 'IFRC Go - {type}',
    tableEmergenciesTitle: 'All {title} Emergencies',
    reportsTableTitle: 'All {title} Field Reports',
    operationsWithEmergency: 'Operation without Emergency Page',
    tableAppealsTitle: 'All {title} {noun}',
    tableAllAlertsTitle: 'All Surge Alerts',
    tableAllEruTitle: 'All Deployed ERUs',
    tableAllPersonnel: 'All Deployed Personnel',

    uhohPageNotFoundTitle: 'IFRC Go - Page not found',
    uhohPageNotFound: 'Page not found',
    uhohPageDescription: 'The requested page does not exist or may have been removed.',
    viewPerFormsTitle: 'IFRC Go - PER',
    accountInformation: 'Account Information',
    accountNotification: 'Notifications',
    accountPerForms: 'PER forms',
    accountWeeklyDigest: 'Weekly Digest',
    accountWeeklyDigestDescription: 'Select to get a weekly compilation of emergency events based on your preferences.',
    accountNewEmergencies: 'New Emergencies',
    accountNewEmergenciesDescription: 'Select to receive notifications for new emergency events (includes Field Reports, GDACS alerts and WHO Alerts).',
    accountNewOperation: 'New Operations',
    accountNewOperationDescription: 'Select to receive notifications of new IFRC supported emergency operations.',
    accountGeneralAnnouncements: 'General Announcements',
    accountNewRecords: 'New records',
    accountModifiedRecords: 'Modified records',
    accountSurgeAlerts: 'Surge alerts',
    accountDeplyomentMessages: 'Deployment Messages',
    accountsurgeAEM: 'Approaching End of Mission',
    accountPerDueDate: 'PER Due Dates',
    accountRegionAfrica: 'Africa',
    accountRegionAsia: 'Asia Pacific',
    accountRegionMENA: 'MENA',
    accountRegionEurope: 'Europe',
    accountRegionAmerica: 'Americas',
    accountCouldNotLoad: 'Could not load user profile',
    accountError: 'Error:',
    accountUpdated: 'Profile updated',
    accountEditProfile: 'Edit Profile',
    accountChangePassword: 'Change my password',
    accountCancel: 'Cancel',
    accountFirstName: 'First Name',
    accountLastName: 'Last Name',
    accountPhoneNumber: 'Phone Number',
    accountCity: 'City',
    accountOrganization: 'Organization',
    accountOrganizationType: 'Organization Type',
    accountDepartment: 'Department',
    accountPosition: 'Position',
    accountSave: 'Save',
    accountSubmittedReports: 'Submitted Field Reports',
    accountDeleteContact: 'To delete a field report, contact',
    accountDeleteInfo: 'the IM team',
    accountSubscriptionPreferences: 'Subscription preferences',
    accountSubscriptionTypes: 'Notification types',
    accountSubscriptionTypesDescription: 'Set basic notification types.',
    accountRegionalNotification: 'Regional notifications',
    accountRegionalNotificationDescription: 'Select one or more regions to receive notifications about.',
    accountCountryLevel: 'Country-level notifications',
    accountCountryLevelDescription: 'Select one or more countries to receive notifications about.',
    accountDisasterCategory: 'Disaster types',
    accountDisasterCategoryDescription: 'Get notified about new disasters in these categories.',
    accountSurgeNotification: 'Surge Notifications',
    acccountOtherNotification: 'Other Notifications',
    accountUnfollow: 'Unfollow',
    accountCurrentlyFollowing: 'Operations currently following',
    accountTitle: 'IFRC Go - Account',
    accountSubscriptionError: 'Subscriptions coming soon',
    accountSubscriptionTitle: 'Subscriptions',
    accountPerError: 'Please login to view content',
    accountPerTitle: 'PER Forms',
    accountPerPermission:'Please Log In',
    accountOperationFollowing: 'Operations following',
    perAccountPoliceStrategy: 'Area 1: Policy and Standards',
    perAccountAnalysis: 'Area 2: Analysis and Planning',
    perAccountOperationalCapacity: 'Area 3: Operational capacity',
    perAccountOperationalCapacity2: 'Area 3: Operational capacity 2',
    perAccountCoordination: 'Area 4: Coordination',
    perAccountSupport: 'Area 5: Operations support',
    perAccountTitle: 'New PER Forms',
    perAccountDescription: 'Click on the following links to access the PER forms, where you can select individual National Societies.',
    perAccountChooseCountry: 'Choose National Society ',
    perAccountOverview: 'Overview',
    perAccountNewAssessment: 'Start a New Assessment',
    perAccountCompletedAssessments: 'Completed PER Assessments',
    perAccountActiveForms: 'Active PER Forms',
    perAccountSelectCountryPlaceholder: 'Select Country',
    perdocumentArea: 'Area',
    perdocumentAreas: 'Areas',
    perdocumentOverview: 'Overview',
    perdocumentView: 'View',
    perTableDate: 'Updated Date',
    perTableAssessmentNumber: 'Assessment number',
    perTableFormsIncluded: 'Forms included',
    perDraftEdit: 'Edit',
    perDraftDelete: 'Delete',
    perDraftHeading: 'Active drafts',
    mainMapDataError: 'Data not available.',
    mainMapDownloadHeaderTitle: 'Ongoing operations',
    mainMapViewFullscreen: 'View in fullscreen',
    mainMapClose: 'Close the Map',
    mainMapPresentation: 'Presentation Mode',
    emergencyTypesAll: 'All Emergency Types',
    explanationBubbleScalePoints: 'Scale points by',
    explanationBubblePopulationLabel: '# of people targeted',
    explanationBubbleAmountLabel: 'IFRC financial requirements',
    explanationBubbleType: 'Type',
    explanationBubbleEmergencyAppeal: 'Emergency appeal',
    explanationBubbleDref: 'DREF',
    explanationBubbleMovement: 'Movement response',
    explanationBubbleEAP: 'Early Action Protocol Activation',
    explanationBubbleMultiple: 'Multiple types',
    explanationBubbleDeployments: 'Deployments',
    operationPopoverClose: 'Close popover',
    operationPopoverDismiss: 'Dismiss',
    operationPopoverPeopleAffected: 'People Targeted',
    operationPopoverAmountRequested: 'Amount Requested (CHF)',
    operationPopoverAmountFunded: 'Amount Funded (CHF)',
    operationPopoverActivity: 'Activity: {activity}',
    operationPopoverStart: 'Start: {start}',
    operationPopoverEnd: 'End: {end}',


    editPerFormsTitle: 'IFRC Go - Emergencies',
    footerAboutGo: 'About Go',
    footerAboutGoDesc: 'IFRC GO is a Red Cross Red Crescent platform to connect information on emergency needs with the right response.',
    footerFindOutMore: 'Find out more',
    footerHelpfulLinks: 'Helpful links',
    footerOpenSourceCode: 'Open Source Code',
    footerApiDocumentation: 'API Documentation',
    footerOtherResources: 'Other Resources',
    footerContactUs: 'Contact Us',
    footerIFRC: '© IFRC 2020',
    perFormTitle: 'IFRC Go - PER Form',
    perFormComponentSaveDraft: 'Save as draft',
    perFormComponentSubmitForm: 'Submit',
    perFormComponentSave: 'Save',
    perFormComponentCreate: 'Create',
    perFormExitForm: 'Exit form',
    perFormAlertCreated: 'PER Form created, redirecting...',
    perFormAlertUpdated: 'PER Form updated, redirecting...',
    perFormAlertDeleted: 'PER Form deleted, redirecting...',
    perFormsAlertCreated: 'PER Forms created, redirecting...',
    perFormsAlertUpdated: 'PER Forms updated, redirecting...',
    perFormsAlertUpdatedNoRedirect: 'PER Forms updated',
    perFormsAlertDeleted: 'PER Forms deleted, redirecting...',
    perOverviewAlertCreated: 'PER Overview created, redirecting...',
    perOverviewAlertUpdated: 'PER Overview updated, redirecting...',
    perOverviewAlertUpdatedNoRedirect: 'PER Overview updated',
    perOverviewAlertDeleted: 'PER Overview deleted, redirecting...',
    perOverviewHeaderCurrent: 'Current PER Assessment',
    perOverviewHeaderPrevious: 'Previous PER Assessment',
    perOverviewHeaderReviews: 'Reviews Planned',
    perOverviewHeaderContact: 'Contact Information',
    perOverviewSaveAndContinue: 'Follow to the forms',
    perOverviewNS: 'National Society*',
    perOverviewEPIBenchmarks: 'EPI benchmarks',
    perOverviewAsmtNumberDescription: 'The number of times that the National Society has gone through PER assessments',
    perOverviewWhatMethod: 'What method has this assessment used',
    perOverviewPrevAssessmentDate: 'Date of previous PER assessment',
    perOverviewPrevAssessmentType: 'Type of previous PER assessment',
    perAssessmentTitle: 'PER Assessment',
    perAssessmentSubmit: 'Final Submit',
    perAssessmentModalMessage: 'You won\'t be able to edit or delete the assessment after you submit it.',
    perAssessmentModalDelete: 'Are you sure you want to delete the assessment?',
    overviewFormExit: 'Exit form',
    overviewFormHeading: 'WELCOME TO THE PREPAREDNESS FOR EFFECTIVE RESPONSE - OVERVIEW FORM',
    overviewFormGeneralInfo: 'General Information',
    overviewFormNationalSociety: 'National Society:',
    overviewFormStartDate: 'Date of assessment*',
    overviewFormTypeCapacityAssessment: 'Type of assessment*',
    overviewFormBranchInvolved: 'Branches involved',
    overviewFormFocalPoint: 'NS focal point name',
    overviewFormFocalPointEmail: 'NS focal point email',
    overviewFormFocalPointPhone: 'NS focal point phone number',
    overviewFormPartnerFocalPoint: 'Partner focal point name',
    overviewFormPartnerFocalPointEmail: 'Partner focal point email',
    overviewFormPartnerFocalPointPhone: 'Partner focal point phone number',
    overviewFormPartnerFocalPointOrganization: 'Partner focal point organization name',
    overviewFormPreviousPer: 'Have you had a previous PER capacity assessment?',
    overviewFormDateOfLastAssessment: 'Date of last capacity assessment',
    overviewFormTypeOfLastAssessment: 'Type of last capacity assessment',
    overviewFormFocus: 'Focus',
    overviewFormFacilitatorInfo: 'Facilitator information',
    overviewFormNameLeadFacilitator: 'Name of lead facilitator',
    overviewFormFacilitatorName: 'PER facilitator / team lead name',
    overviewFormFacilitatorEmail: 'PER facilitator / team lead email',
    overviewFormFacilitatorPhone: 'PER facilitator / team lead phone number',
    overviewFormFacilitatorContact: 'PER facilitator / team lead other contact method',
    overviewFormEmail: 'E-mail',
    overviewFormPhoneNumber: 'Phone number',
    overviewFormSkype: 'Skype address',
    overviewFormDateOfMidReview: 'Estimated date of mid-term review',
    overviewFormDateOfNext: 'Estimated date of next assessment',
    overviewFormPreparednessHeading: 'WELCOME TO THE PREPAREDNESS FOR EFFECTIVE RESPONSE - OVERVIEW FORM',
    overviewFormSaveAsDraft: 'Save as draft',
    overviewFormDraftInfo: 'Take care, only Drafts can be edited afterwards!',
    overviewFormFinalized: 'Finalized',
    overviewFormIsEPI: 'Do you want to assess the preparedness of your national society for epidemics and pandemics?',
    fieldReportCreate: 'Create Field Report',
    fieldReportIncomplete: 'Page {step} of 4 incomplete.',
    fieldReportFix: 'To continue please fix:',
    fieldReportSubmit: 'Submit',
    fieldReportContinue: 'Continue',
    fieldReportGoBack: 'Go back to previous step',
    fieldReportBack: 'Back',
    cmpActionDescriptionLabel: 'Description',
    cmpContactName: 'Name',
    cmpContactTitle: 'Title',
    cmpContactEmail: 'Email',
    cmpContactPhone: 'Phone',
    cmpEruAddNew: 'Add new ERU',
    cmpEruType: 'Type',
    cmpEruStatus: 'Status',
    cmpEruUnits: 'Units',
    cmpEruDelete: 'Delete ERU',
    cmpPlannedStatus: 'Status',
    cmpReportSource: 'Specification',
    cmpSourceLabel: 'Source',
    fieldReportTitle: 'IFRC Go - Field Report',
    fieldReportResourceNotFound: 'Resource Not Found!',
    fieldReportResourceDescription: 'The resource doesn\'t exist or you are not authorized to access this resource.',
    fieldReportGoToLogin: 'Go to login page',
    fieldReportSummaryTitle: 'IFRC Go - {reportName}',
    fieldReportEdit: 'Edit Report',
    fieldReportRiskAnalyisis: 'Risk Analysis',
    fieldReportDescription: 'Description',
    fieldReportDateOfData: 'Date of Data',
    fieldReportForecastedDate: 'Forecasted Date of Impact',
    fieldReportStartDate: 'Start Date',
    fieldReportCovidReport: 'COVID-19 Field Report',
    fieldReportRequest: 'Requests for Assistance',
    fieldReportGovernmentRequest: 'Government Requests International Assistance: ',
    fieldReportInternationalRequest: 'NS Requests International Assistance:',
    fieldReportInformationBulletin: 'Information Bulletin Published',
    fieldReportActionTaken: 'Actions taken by others',
    fieldReportActionTakenBy: 'Actions taken by {orgDisplayName}',
    fieldReportSources: 'Sources',
    fieldReportContacts: 'Contacts',
    fieldReportCases: 'Cases: ',
    fieldReportSuspectedCases: 'Suspected Cases: ',
    fieldReportProbableCases: 'Probable Cases: ',
    fieldReportConfirmedCases: 'Confirmed Cases: ',
    fieldReportDeadCases: 'Dead: ',
    fieldReportCasesSince: 'Number of new cases since last Field Report',
    fieldReportDeathsSince: 'Number of new deaths since last Field Report',
    fieldReportSource: 'Source',
    fieldReportAssistedByGovernment: 'Assisted by Government:',
    fieldReportAssistedRCRC: 'Assisted by RCRC Movement:',
    fieldReportLocalStaff: 'Local Staff: ',
    fieldReportVolunteers: 'Volunteers: ',
    fieldReportDelegates: 'Delegates: ',
    fieldReportInjured: 'Injured (RC): ',
    fieldReportMissing: 'Missing (RC): ',
    fieldReportDead: 'Dead (RC): ',
    fieldReportDisplaced: 'Displaced (RC): ',
    fieldReportAffected: 'Affected (RC):',
    fieldReportInjuredGov: 'Injured (Government): ',
    fieldReportMissingGov: 'Missing (Government): ',
    fieldReportDeadGov: 'Dead (Government):',
    fieldReportDisplacedGov: 'Displaced (Government): ',
    fieldReportAffectedGov: 'Affected (Government): ',
    fieldReportInjuredOther: 'Injured (Other): ',
    fieldReportMissingOther: 'Missing (Other): ',
    fieldReportDeadOther: 'Dead (Other): ',
    fieldReportDisplacedOther: 'Displaced (Other): ',
    fieldReportAffectedOther: 'Affected (Other): ',
    fieldReportAsstByGov: 'Assisted by Government:',
    fieldReportAsstByRCRC: 'Assisted by RCRC Movement:',
    fieldReportPotentiallyAffected: 'Potentially Affected (RC): ',
    fieldReportHighestRisk: 'People at Highest Risk (RC): ',
    fieldReportAffectedPop: 'Affected Pop Centres (RC):',
    fieldReportPotentiallyAffectedGov: 'Potentially Affected (Government): ',
    fieldReportHighestRiskGov: 'People at Highest Risk (Government): ',
    fieldReportPotentiallyAffectedOther: 'Potentially Affected (Other): ',
    fieldReportHighestRiskOther: 'People at Highest Risk (Other): ',
    fieldReportAffectedPopOther: 'Affected Pop Centres (Other): ',
    fieldReportNumericTitle: 'Numeric details',
    fieldReportSourcesOther: 'Sources for data marked as Other',
    fieldReportNationalSocietyLabel: 'National Society',
    fieldReportIFRCLabel: 'IFRC',
    fieldReportPNSLabel: 'any other RCRC Movement actors',

    deploymentsTitle: 'IFRC Go - Deployments',
    deploymentsDeployedERU: 'Deployed ERUs',
    deploymentsOverviewByEmergencies: 'Deployments Overview By Emergencies',
    deploymentsOverviewViewAll: 'View All Deployed Personnel',
    deploymentsOverviewTableHeaderEmergency: 'Emergency',
    deploymentsOverviewTableHeaderOrg: 'Deploying Organization',
    deploymentsOverviewTableHeaderSurge: 'Surge Type',
    deploymentsOverviewTableHeaderNo: 'Number of Deployments',
    deploymentsDeployedRR: 'Deployed Rapid Response',
    deploymentsDeployedHeops: 'Deployed HEOPS',
    deploymentEruDeploymentTypes: 'ERU Deployment Types',
    deploymentNumber: 'Number of Deployments by NS',
    nationalSocieties: 'National Societies',
    readinessFilteredERUs: 'Filter Ready ERUs',
    readinessResetFilters: 'Reset Filters',
    readinessLastUpdated: 'Last updated {date}',
    readinessReadyErus:' {numReady} Ready ERUs',
    readinessDeployedErus: '{numDeployed} Deployed ERUs',
    mapComponentExport: 'Export',
    mapFooterDisclaimer: 'The maps used do not imply the expresion of any opinion on the part of the International Federation of Red Cross and Red Crescent Societies or National Societies concerning the legal status of a territory or of its authorities, Data sources: IFRC, OSM contributors, Map box.',
    countryMapError: 'Data not available.',
    countryMapDownloadedTitle: 'Movement activities',
    mapPopoverTitle: 'Deployments in {name}',
    deploymentsMapHeading: 'Deployments by Country',
    deploymentsMapKey: 'Key',
    deploymentsMapDeployedTitle: 'Deployed FACT, RDRT/RIT, & HEOPs',
    deploymentsMapDeployed: 'Deployed {activeFilter}',
    deploymentsMapLegendTitle: 'Emergency Response Units deployed',
    deploymentsMapScaleGradient: 'Scale Gradient',
    mapPopOverDismiss: 'Dismiss',
    emergenciesMapHeading: 'Emergencies by Country',
    emergenciesMapDownloadTitle: 'Emergencies',
    emergenciesMapWithoutIFRC: 'Without IFRC response*',
    emergenciesMapWithIFRC: 'With IFRC response',
    emergenciesMapMixResponse: 'Mixed Level of response',
    emergenciesMapDescription: '*IFRC response indicates the existence of appeal(s) and/or DREF(s) for a given emergency.',
    emergenciesMapNoData: 'Data not available.',
    emergenciesMapPopoverDismiss: 'Dismiss',
    emergenciesMapPopoverPeopleAffected: '{numAffected} People Affected',
    emergenciesMapPopoverTotalEmergencies: '{totalEmergencies} Emergencies',
    emergenciesMapPopoverWithoutIFRC: '{withoutResponse} without IFRC response',
    emergenciesMapPopoverWithIFRC: '{withResponse} with IFRC response',
    emergencyMapExport: 'Export',
    emergencyMapSelected: 'selected',
    emergencyMapAffectedCountry: 'Affected country',
    emergencyMapAffectedRegion: 'Affected provinces',
    emergencyMapBorder: 'border',
    emergencyMapBorderDisputed: 'Disputed borders',
    emergencyMapTerritory: 'territory',
    emergencyMapDisputedTerritory: 'Disputed territory',

    videoCarouselHeading3W: '3W - Who does What, Where.',
    videoCarouselSubHeading3W: 'The "Who does What, Where", or 3W, is a GO platform tool to enhance coordination, improve disaster analysis, and elevate the speed and quality of Red Cross Red Crescent emergency response.',
    videoCarouselHeading3WInstructional: '3W Instructional',
    videoCarouselSubHeading3WInstructional: 'View this video tutorial on how to manage the 3W tool data  (Who, What, Where) and generate useful and visually pleasant information products on the GO Platform - International Federation of Red Cross Red Crescent (IFRC).',
    videoCarouselHeadingFieldReport: 'Field Report COVID-19',
    videoCarouselSubHeadingFieldReport: 'View this video tutorial on how to create a "Field Report" COVID-19 on the GO Platform - International Federation of Red Cross Red Crescent (IFRC).',
    videoCarouselHeadingPER: 'Preparedness for Effective Response Module',
    videoCarouselSubHeadingPER: 'View this video tutorial on how to navigate on the Preparedness for Effective Response (PER) module on the GO Platform - International Federation of Red Cross Red Crescent (IFRC).',
    videoCarouselHeadingSubscribe: 'Subscriptions to User Notifications',
    videoCarouselSubHeadingSubscribe: 'View this video tutorial on how to "Subscribe to User Notifications" on the GO Platform - International Federation of Red Cross Red Crescent (IFRC).',
    videoCarouselHeadingTranslations: 'Translation feature',
    videoCarouselSubHeadingTranslations: 'View this video tutorial on how to use the translation feature on the GO Platform - International Federation of Red Cross Red Crecent (IFRC).',
    emergenciesDashHeading: 'Emergencies by Type',
    emergenciesDashDate: 'Date',
    emergenciesDashTotal: 'Total',
    emergenciesDashOverLastYear: 'Emergencies Over the Last Year',
    emergenciesDashLast30Days: 'Emergencies in the last 30 days',
    emergenciesDashOverTime: 'DREFS and Appeals over time',
    eruTableTitle: 'Deployed ERUs',
    eruTableNameTitle: 'Owner',
    eruTableNameType: 'Type',
    eruTablePersonnel: 'Personnel Units',
    eruTableEquipment: 'Equipment Units',
    eruTableCountriesDeployed: 'Country Deployed to',
    eruTableEmergency: 'Emergency',
    eruTableViewCountry: 'View Country',
    eruTableViewEmergency: 'View Emergency',
    eruTableViewAll: 'View all deployed ERUs',
    fieldReportsTableTitleDefault: 'Field Reports',
    fieldReportsTableLoginRequired: 'You must be logged in to view field reports. ',
    fieldReportsTableLogin: 'Login',
    fieldReportsTableCreatedAt: 'Created At',
    fieldReportsTableName: 'Name',
    fieldReportsTableEmergency: 'Emergency',
    fieldReportsTableDisasterType: 'Disaster Type',
    fieldReportsTableCountry: 'Countries',
    fieldReportsTableViewAll: 'View Field Report',
    fieldReportsTableViewEmergency: 'View Emergency',
    fieldReportsTableViewAllReports: 'View all field reports',
    fieldReportsTableProblem: '(Performance problems with Export Table)',
    fieldReportsViewAllIn: 'View all field reports in',
    newPasswordField: 'New password',
    newPasswordConfirm: 'Confirm new password',
    newPasswordSubmit: 'Submit',
    personnelTableTitleRR: 'Rapid Response Personnel',
    personnelTableStartDate: 'Start Date',
    personnelTableEndDate: 'End Date',
    personnelTableName: 'Name',
    personnelTableRole: 'Position',
    personnelTableType: 'Type',
    personnelTableFrom: 'Deploying Party',
    personnelTableDeployedTo: 'Deployed To',
    personnelTableEmergency: 'Emergency',
    personnelTableViewCountry: 'View Country',
    personnelTableViewAllDeployed: 'View all deployed personnel',
    preparednessColumnGraphTitle: 'PER components results',
    preparednessOverviewTitle: 'Preparedness For Effective Response Overview',
    preparednessOverviewCurrent: 'Current PER process phase',
    preparednessOverviewOrientation: 'Orientation',
    preparednessOverviewAssessment: 'Assessment',
    preparednessOverviewPrioritization: 'Prioritization',
    preparednessOverviewPlanAction: 'Plan of action',
    preparednessOverviewAction: 'Action and Accountability',
    preparednessOverviewAssessmentDate: 'Date of the assessment',
    preparednessOverviewPerProcessType: 'Type of assessment',
    preparednessOverviewFocus: 'Focus',
    preparednessOverviewFocalPoint: 'Focal point',
    preparednessOverviewEmail: 'Email',
    preparednessOverviewCycle: 'Assessment cycle',
    preparednessSummaryTitle: 'PER Components And Sub-Component results',
    preparednessSummaryHighPerformance: 'High performance',
    preparednessSummaryComponents: 'COMPONENTS',
    preparednessSummaryExists: 'Exists',
    preparednessSummaryNeedsImprovement: 'Needs improvement',
    preparednessSummaryExistsPartially: 'Partially exists',
    preparednessSummaryDoesnotExists: 'Does not exist',
    preparednessSummaryNotReviewed: 'Not reviewed',
    preparednessWorkPlanTitle: 'Preparedness work plan',
    preparednessWorkPlanAdd: 'Add',
    preparednessWorkPlanPrioritization: 'Prioritization:&nbsp;',
    preparednessWorkPlanComponent: 'Component:&nbsp;',
    preparednessWorkPlanBenchmark: 'Benchmark:&nbsp;',
    preparednessWorkPlanActions: 'Actions:&nbsp;',
    preparednessWorkPlanComments: 'Comments:&nbsp;',
    preparednessWorkPlanTimeline: 'Timeline:&nbsp;',
    preparednessWorkPlanFocalPoint: 'Focal Point:&nbsp;',
    preparednessWorkPlanStatus: 'Status:&nbsp;',
    preparednessWorkPlanRequired: 'Required:&nbsp;',
    preparednessWorkPlanPrioritizationLabel: 'Prioritization',
    preparednessWorkPlanComponentLabel: 'Component',
    preparednessWorkPlanBenchmarkLabel: 'Benchmark',
    preparednessWorkPlanActionsLabel: 'Actions',
    preparednessWorkPlanCommentsLabel: 'Comments',
    preparednessWorkPlanTimelineLabel: 'Timeline',
    preparednessWorkPlanFocalPointLabel: 'Focal Point',
    preparednessWorkPlanStatusLabel: 'Status',
    preparednessWorkPlanSupportLabel: 'Support required',
    emergenciesStatsTitle: 'Emergencies',
    emergenciesStatsAffected: 'Affected People',
    emergenciesStatsRequested: 'Requested Amount',
    emergenciesStatsFunding: 'Funding (CHF)',
    alertDismissTitle: 'Dismiss alert',
    alertDismiss: 'Dismiss',
    confirmModalCancel: 'Cancel',
    confirmModalOk: 'Ok',
    confirmModalConfirm: 'Confirm',
    expandableShowLess: 'Show less',
    expandableShowMore: 'Show more',
    exportButtonExportTable: 'Export Table',
    exportButtonDownloadingProgress: 'Downloading... {progress}%',
    globalHeaderBannerTitle: 'COVID-19 global situational information and technical guidance available {link}.',
    globalHeaderBannerHere: 'here',
    globalHeaderBannerSurveyTitle: 'Take 5 mins to tell us what you think - {link}',
    globalHeaderBannerSurveyLink: 'GO SURVEY',
    threeWFilterReportingNs: 'National Societies',
    threeWFilterReportingNsPlaceholer: 'All National Societies',
    threeWProgrammeTypePlaceholder: 'All Programme Types',
    threeWSector: 'Sectors of Activity',
    threeWSectorPlaceholder: 'All Sectors',
    threeWTag: 'Tag',
    threeWTagPlaceholder: 'All Tags',
    threeWStatusPlaceholder: 'All Status',
    threeWAdd: 'Add',
    threeWExport: 'Export',
    threeWDeleteProject: 'Delete project',
    threeWDeleteProjectMessage: 'Are you sure you want to delete the project?',
    threeWMapLastUpdate: 'Last update',
    threeWMapStatus: 'Status',
    threeWMapSector: 'Sector',
    threeWMapRegions: 'Regions',
    threeWMapProgrammeType: 'Programme type',
    threeWMapBudget: 'Budget',
    threeWMapProjects: '3W Projects',
    threeWProjectDetails: 'Red Cross / Red Crescent activities',
    threeWClose: 'Close',
    threeWProjectName: 'Project Name',
    threeWStartDate: 'Start Date',
    threeWEndDate: 'End Date',
    threeWProjectDistricts: 'Project Districts',
    threeWNationalSociety: 'Reporting National Society',
    threeWBudgetAmount: 'Budget Amount (CHF)',
    threeWProgrammeType: 'Programme Type',
    threeWStatus: 'Status',
    threeWCurrentOperation: 'Current IFRC Operation',
    threeWDisasterType: 'Disaster Type',
    threeWOperationType: 'Operation Type',
    threeWPrimarySector: 'Primary Sector',
    threeWTagging: 'Tagging',
    threeWTargeted: 'Targeted',
    threeWMale: 'Male',
    threeWFemale: 'Female',
    threeWOther: 'Other',
    threeWTotal: 'Total',
    threeWReached: 'Reached',
    projectFormReportingNational: 'Reporting National Society *',
    projectFormReportingHelpText: 'Select National Society that is carrying out the activity.',
    projectFormReportingTooltip: 'It can be either the National Society where the disaster has taken place or a different National Society that is carrying out an activity in support of the response.',
    projectFormFetching: 'Fetching national societies...',
    projectFormCountryTitle: 'Country and Region / Province* ',
    projectFormCountryHelpText: 'Select the country and region where the disaster is taking place.',
    projectFormCountryTooltip: 'The region can be referred to as states, provinces, or Admin Level 1. Choose countrywide for activities that are not limited to specific places. If the project takes place on multiple regions please submit each region separately using the clone-function on the country view table',
    projectFormCountryLabel: 'Country',
    projectFormCountryPlaceholder: 'Fetching countries...',
    projectFormDistrictLabel: 'Region / Province',
    projectFormDistrictFetching: 'Fetching regions...',
    projectFormDistrictSelect: 'Select regions',
    projectFormTypeOfOperation: 'Type of Operation / Programme*',
    projectFormTypeOfOperationTooltip: 'The operation type can be either an Emergency Operation or a Programme. Emergency Operations are new efforts linked to a specific emergency. Programs are ongoing work linked to a disaster. \n\nIf you choose Domestic, Bilateral, or Multilateral Programme, select Disaster Type that best fits the situation. Disasters are often multifactorial. Please choose the type that makes the most sense, recognizing that disasters are often the result of many complex factors. \nIf you choose Bilateral Emergency Operation, then no additional info is required. \nIf you choose Multilateral Emergency Operation, then please identify linked IFRC Emergency Operation.',
    projectFormProgrammeType: 'Programme Type:',
    projectFormProgrammeTooltip: 'Select the Programme Type. Choose from the options of Domestic, Bilateral, Multilateral. If you choose Domestic Emergency Operation, then identify linked Ongoing Emergency.',
    projectFormAll: 'All',
    projectFormOperationType: 'Operation Type',
    projectFormProgrammeTypeLabel: 'Programme Type',
    projectFormCurrentOperation: 'Current IFRC Operation*',
    projectFormCurrentEmergency: 'Current Emergency Operation*',
    projectFormCurrentEmergencyHelpText: 'The list is populated from current emergency operations related to the selected country. If necessary, create the related emergency through a field report',
    projectFormFetchingEvents: 'Fetching events...',
    projectFormDisasterType: 'Disaster Type*',
    projectFormDisasterTypePlaceholder: 'Select an operation to view its disaster type',
    projectFormProjectName: 'Project Name*',
    projectFormHelpText: 'Enter a name that differentiates your activity or project from other initiatives taking place in the response.',
    projectFormTooltip: 'The 3w system does allow for duplicate activities projects with the same name, but please choose a descriptive and original title.',
    projectFormSectorTitle: 'Sector and Tagging',
    projectFormPrimarySector: 'Primary Sector:',
    projectFormPrimarySectorText: 'Choose the sector that best represents the activity or project.',
    projectFormTagging: 'Tagging:',
    projectFormTaggingText: 'Projects are often multi-sector. After choosing the primary sector, feel free to add additional sector ‘tags’.',
    projectFormTaggingTooltip: 'CEA - Community engagement and accountability is a set of communication and participation activities that help put communities at the centre of the response. \n \nDRR - Disaster risk reduction is the concept and practice of reducing disaster risks through systemic efforts. It encompasses a broad range of activities – from ensuring that legislative and policy approaches reflect known hazards, to community-based initiatives and technical solutions such as early warning systems. \n \nEducation - Educational programming for people affected by disasters. \n \nHealth - Immediate assistance for disaster-affected people and longer-term activities that save lives and improve health outcomes. Separated into clinical and public health. \n \nLivelihood and basic needs - Livelihoods are the capabilities, assets and activities required for generating income and securing a basic means of living. \n \nMigration - Aid and protection for migrants and displaced people, in countries of origin, transit and destination, whatever their legal status. \n\nNS Strengthening - Support to the auxiliary role, strategy, governance and accountability; strengthening areas such as financial management, communications, fundraising; increase volunteer engagement; improve external relations; or ramp up their preparedness for responding to emergencies or improve the planning and execution of programmes and services they provide. \n \nPGI - Protection, gender and inclusion (PGI) in emergencies including sexual and gender-based violence and disability inclusion. \n \nShelter - Immediate and long term shelter assistance.  \n\nWASH - Water, sanitation and hygiene support. \n \n \nIt is possible to add none, one or many tags using the definitions listed above. For COVID-19 related projects please add the COVID-19 tag',
    projectFormPrimarySectorSelect: 'Primary Sector* ',
    projectFormSecondarySectorLabel: 'Tagging',
    projectFormMultiLabel: 'Start and End Dates* ',
    projectFormMultiLabelHelpText: 'Choose the date when the work on the activity or project begins',
    projectFormMultiLabelTooltip: 'Choose the date when the project is likely to end. Remember, you can easily return and edit this data if plans evolve.',
    projectFormStartDate: 'Start Date',
    projectFormEndDate: 'End Date',
    projectFormBudgetTitle: 'Budget and Status*',
    projectFormBudget: 'Budget:',
    projectFormBudgetText: 'Enter the budget for the activity or project.',
    projectFormProjectStatus: 'Project status:',
    projectFormProjectStatusText: 'The project status (planned and ongoing) is automatically defined by the current date and the submitted project timeline.',
    projectFormProjectTooltip: 'The budget includes the total costs for the listed activity or project. \nThe project can be marked completed, which makes the people reached a required value.',
    projectFormProjectBudget: 'Project Budget (CHF)',
    projectFormProjectCompleted: 'Completed',
    projectFormProjectStatusTitle: 'Project Status',
    projectFormPeopleTageted: 'People Targeted',
    projectFormPeopleTagetedHelpText: 'Enter the number of people that the project plans to reach through the work.',
    projectFormPeopleTagetedTooltip: 'The options are: \nMale - People who identify as having the male gender \nFemale - People who identify as having the female gender \nOther - Other can include data such as “other sex/gender”, “undisclosed”, “unknown”, etc \nTotal - The total number of people included in the subcategories above',
    projectFormMale: 'Male',
    projectFormFemale: 'Female',
    projectFormOther: 'Other',
    projectFormTotal: 'Total* ',
    projectFormPeopleReached: 'People Reached',
    projectFormPeopleReachedHelpText: 'Enter the total number of people reached already with (TOTAL, male, female, other) according to the definitions above.',
    projectFormPeopleReachedTooltip: 'Please follow the Counting People Reached guidelines as establishing the Federation-wide Databank and Reporting System.',
    projectFormPeopleReachedMale: 'Male',
    projectFormPeopleReachedFemale: 'Female',
    projectFormPeopleReachedOther: 'Other',
    projectFormProjectVisibility: 'Project visibility*',
    projectFormProjectVisibilityHelpText: 'Enter the desired visibility of the project',
    projectFormProjectVisibilityTooltip: 'The IFRC Only option limits viewing to only those vetted IFRC members who are logged into the GO platform and have the required permissions to view the data. Most programs should be in public mode, and we should only use the IFRC Only option in limited sensitive contexts where issues such as protection concerns are present. As with the wider GO Platform, there is no place for Personal or Community Identifiable data on this type of coordination platform. Sensitive data or information that could jeopardize the safety of staff or project participants should not be shared.',
    projectFormError: 'There was an error submitting the request!',
    projectFormSubmit: 'Submit',
    projectFormSubmitting: 'Submitting...',
    projectFormModalTitle: 'Red Cross / Red Crescent activities',
    projectFormModalClose: 'Close',
    regionOverviewTitle: 'Regions',
    regionOverviewSectorActivity: 'Activities by sector',
    regionOverviewActivityStatus: 'Activity status overview',
    summaryStatsLabel: 'Active National societies',
    summaryStatsTotalBudget: 'Total budget',
    projectListTableStartDate: 'Start Date',
    projectListTableEndDate: 'End Date',
    projectListTableProjectName: 'Project Name',
    projectListTableSupportingNs: 'Reporting NS',
    projectListTableActivitySector: 'Sector of Activity',
    projectListTableBudget: 'Budget (CHF)',
    projectListTableProgrammeType: 'Programme Type',
    projectListTableDisaster: 'Disaster Type',
    projectListTablePeopleTargeted: 'People Targeted',
    projectListTablePeopleReached: 'People Reached',
    projectListTableStatus: 'Status',
    projectListTableLastUpdated: 'Last Updated',
    projectListTableViewDetails: 'View details',
    projectListTableEdit: 'Edit',
    projectListTableDuplicate: 'Duplicate',
    projectListTableHistory: 'History',
    projectListTableDelete: 'Delete',
    climateChartHeading: 'Climate chart (C°)',
    climateChartKeyClimateHeading: 'Key climate events',
    countryOverviewSource: 'Source:',
    countryOverviewFDRS: 'FDRS',
    informIndicatorsTitle: 'Inform indicators',
    keyIndicatorsTitle: 'Key indicators',
    keyIndicatorsPopulation: 'Population',
    keyIndicatorsPopulationPercent: 'Urban pop (% pop)',
    keyIndicatorsGDP: 'GDP',
    keyIndicatorsGNI: 'GNI / capita',
    keyIndicatorsPovertyPercent: 'Poverty (% pop)',
    keyIndicatorsLifeExpectancy: 'Life expentancy',
    keyIndicatorsLiteracy: 'Literacy',
    NSIndicatorsTitle: 'National society indicators',
    NSIndicatorsIncome: 'Income (CHF)',
    NSIndicatorsExpenditure: 'Expenditures (CHF)',
    NSIndicatorsVolunteers: 'Volunteers',
    NSIndicatorsTrained: 'Trained in first aid',
    pastCrisesEventsTitle: 'Past crises events',
    pastCrisesEventsConflict: 'Conflict events',
    pastEpidemicsTitle: 'Past epidemics',
    pastOperationsTitle: 'Past operations',
    pastOperationsPeopleTargeted: 'People targeted',
    pastOperationsFunding: 'Funding requirements',
    populationMapTitle: 'Population map',
    seasonalCalendarTitle: 'Seasonal calendar',
    socialEventsTitle: 'Social events',

    fieldsStep1SummaryLabel: 'Title *',
    fieldsStep1SummaryDescription:  'For Covid-19 Field Reports, please link to the existing country specific emergency page. Please do not link to the Global emergency page.',

    fieldsStep1DisasterTypeLabel: 'Disaster Type *',
    fieldsStep1DisasterTypeDescription: 'If Covid-19 select “Epidemic” as the disaster type',

    fieldsStep1StartDateLabelStartDate: 'Start Date *',
    fieldsStep1StartDateLabelEPI: 'Start Date of emergency/outbreak *',
    fieldsStep1StartDateDescriptionEVT: 'Start date is when some significant effects are felt or when the first significant impact is felt.',
    fieldsStep1StartDateDescriptionEPI: 'The date when the first case is confirmed.',
    fieldsStep1StartDateLabelEW: 'Forecasted Date of Impact *',
    fieldsStep1StartDateDescriptionEW: 'Date at which significant impacts are forecasted to occur.',

    fieldsStep1CountryLabelAffected: 'Affected Country and Province / Region *',
    fieldsStep1CountryLabelEW: 'Potentially Affected Country and Province / Region *',
    fieldsStep1CountryDescriptionEW: 'Anticipated Affected Country and Province / Region',

    fieldsStep1AssistanceLabel: 'Government requests international assistance?',
    fieldsStep1AssistanceDescription: 'Indicate if the government requested international assistance.',

    fieldsStep1NSAssistanceLabel: 'National Society requests international assistance?',
    fieldsStep1NSAssistanceDescription: 'Indicate if the National Society requested international assistance.',

    fieldsStep2HeaderDescription: 'Note that it is not required to put case numbers into your Field Report, but you can input them if you choose to. WHO or your national Government authoritative figures should be used.',
    fieldsStep2OrganizationsEVTEWLabelRC: 'Red Cross / Red Crescent',
    fieldsStep2OrganizationsEVTEWLabelGovernment: 'Government',
    fieldsStep2OrganizationsLabelOther: 'Other',
    fieldsStep2OrganizationsEPILabelHealthMinistry: 'Ministry of Health',
    fieldsStep2OrganizationsEPILabelWHO: 'World Health Organization',

    fieldsStep2SituationFieldsEVTInjuredLabel: 'Injured',
    fieldsStep2SituationFieldsEVTInjuredDescription: 'Number of people suffering from physical injuries, trauma or an illness requiring immediate medical treatment as a direct result of a disaster.',
    fieldsStep2SituationFieldsEVTDeadLabel: 'Dead',
    fieldsStep2SituationFieldsEVTDeadDescription: 'Number of people confirmed dead.',
    fieldsStep2SituationFieldsEVTMissingLabel: 'Missing',
    fieldsStep2SituationFieldsEVTMissingDescription: 'Number of people missing.',
    fieldsStep2SituationFieldsEVTAffectedLabel: 'Affected',
    fieldsStep2SituationFieldsEVTAffectedDescription: 'Number of people requiring immediate assistance during a period of emergency; this may include displaced or evacuated people.',
    fieldsStep2SituationFieldsEVTDisplacedLabel: 'Displaced',
    fieldsStep2SituationFieldsEVTDisplacedDescription: 'Number of people displaced.',
    fieldsStep2SituationFieldsEPICasesLabel: 'Cumulative Cases',
    fieldsStep2SituationFieldsEPICasesDescription: 'Number of registered cases since the start of the outbreak.',
    fieldsStep2SituationFieldsEPISuspectedCasesLabel: 'Suspected Cases',
    fieldsStep2SituationFieldsEPISuspectedCasesDescription: 'Number of suspected cases.',
    fieldsStep2SituationFieldsEPIProbableCasesLabel: 'Probable Cases',
    fieldsStep2SituationFieldsEPIProbableCasesDescription: 'Probable Cases.',
    fieldsStep2SituationFieldsEPIConfirmedCasesLabel: 'Confirmed Cases',
    fieldsStep2SituationFieldsEPIConfirmedCasesDescription: 'Confirmed Cases.',
    fieldsStep2SituationFieldsEPIDeadLabel: 'Cumulative Dead',
    fieldsStep2SituationFieldsEPIDeadDescription: 'Number of people confirmed dead since the start of the outbreak.',
    fieldsStep2SituationFieldsEWPotentiallyAffectedLabel: 'Potentially Affected',
    fieldsStep2SituationFieldsEWPotentiallyAffectedDescription: 'Number of people that are located in the geographic area where the hazard is likely to impact',
    fieldsStep2SituationFieldsEWHighestRiskLabel: 'People at Highest Risk',
    fieldsStep2SituationFieldsEWHighestRiskDescription: 'Number of people that are located in the geographic area where the hazard\'s impact is likely to be the highest',
    fieldsStep2SituationFieldsEWAffectedPopCenteresLabel: 'Largest Population Centres Likely to be Affected',
    fieldsStep2SituationFieldsEWAffectedPopCenteresDescription: 'Names of large cities or towns which are most at risk',

    fieldsStep2SituationFieldsDateEPILabel: 'Date of Data',
    fieldsStep2SituationFieldsDateEPIEstimationLabel: 'The key figures above are reported as of this date',
    fieldsStep2SituationFieldsDateEPIDescription: 'Date of figures reported.',
    fieldsStep2SituationFieldsEPICasesSinceDesciption: 'Number of registered cases since the last field report',
    fieldsStep2SituationFieldsEPIDeathsSinceDescription: 'Number of confirmed dead since the last field report',

    fieldsStep2NotesLabel: 'Notes',
    fieldsStep2SourceOfFiguresLabel: 'Source (of figures)',
    fieldsStep2DescriptionEVTLabel: 'Situational Overview',
    fieldsStep2DescriptionEVTDescription: 'Describe the effects of the hazard, the current context, the affected population and how they have been affected.',
    fieldsStep2DescriptionEVTPlaceholder: 'Example: According to the local government, the overflow of the Zimbizi river has caused extensive flood water damage to low income housing along the river bank. The majority of the affected households do not have sufficient insurance coverage for their assets. The local branch of the National Society is currently assessing how to best support the most vulnerable families affected by the disaster.',
    fieldsStep2DescriptionEPILabel: 'Situational Overview',
    fieldsStep2DescriptionEPIDescription: 'Describe the primary and secondary effects on the health system and affected population.',
    fieldsStep2DescriptionEPICOVDescription: `These questions are meant to function as a guide only:
  Since the last Field Report:
  - How has the situation evolved?
  - How is the situation affecting your National Society and activities?
  - Has this situation resulted in changes to the way your NS operates?
  - How has the population been affected?
  - Are particular groups or geographic locations being impacted more than others and why?`,
    fieldsStep2DescriptionEPIPlaceholder: 'Description of the epidemic',
    fieldsStep2DescriptionCOVIDPlaceholder: 'Describe succinctly (bullet points, short statements) the primary and secondary effects. Please outline how the situation is evolving and what has changed since the last field report. Below are some guiding questions to help complete this section.',
    fieldsStep2DescriptionEWLabel: 'Risk Analysis',
    fieldsStep2DescriptionEWDescription: 'Brief overview of the potential disaster and projected impacts',
    fieldsStep2DescriptionEWPlaceholder: 'Hurricane Sirius is expected to hit the Whinging region early Tuesday morning. The system currently has sustained core wind speeds of 140km/h and gusts up to 170 km/h. The local government has started evacuating thousands of people. The Red Cross branch in Whinging has deployed staff and volunteers to communities at risk to support evacuation and to assist the population in protecting themselves and their livelihoods from the impacts of Sirius.',

    fieldsStep2SituationFieldsEstimation: 'Estimation',
    fieldsStep2EPINotes: 'Notes: Please provide any additional details since the last field report about new geographic locations that are being affected, details on if certain population groups are being affected, and general perspective on if the situation is improving or deteriorating.',

    fieldsStep3Section1FieldsAssistedGovEVTEPILabel: 'Assisted by Government',
    fieldsStep3Section1FieldsAssistedGovEWLabel: 'Number of People Assisted by Government - Early Action',
    fieldsStep3Section1FieldsAssistedRCRCEVTEPILabel: 'Assisted by RCRC Movement',
    fieldsStep3Section1FieldsAssistedRCRCEWLabel: 'Number of People Assisted by RCRC Movement - Early Action',
    fieldsStep3Section1FieldsLocalStaffEVTEPILabel: 'Number of NS Staff Involved',
    fieldsStep3Section1FieldsVolunteersEVTEPILabel: 'Number of NS Volunteers Involved',
    fieldsStep3Section1FieldsExpatsEVTEPILabel: 'Number of RCRC Partner Personnel Involved',
    fieldsStep3Section1FieldsExpatsEVTEPIDescription: 'Personnel from IFRC, ICRC & PNS',

    fieldsStep3CheckboxSectionsNSActionsEVTEPILabel: 'Actions Taken by National Society Red Cross (if any)',
    fieldsStep3CheckboxSectionsNSActionsEWLabel: 'Early Actions Taken by NS',
    fieldsStep3CheckboxSectionsNSActionsEVTEPIDescription: 'Since the beginning of the response, what activities has your NS undertaken? A short description is available for each activity and if your NS has been active in this area during this response, please select it by checking the box. There is a section to provide a description of the activities supported, in this section please focus on what is new since the last field report.',
    fieldsStep3CheckboxSectionsNSActionsEWDescription: 'Select the early action activities undertaken by the National Society and give a brief description',
    fieldsStep3CheckboxSectionsNSActionsEVTPlaceholder: 'Example: The two local branches of the National Society in the affected districts have provided first aid, psychosocial support and basic relief items to the affected families. An evacuation centre has been set up in a local school to accommodate those unable to return to their homes. Groups of Red Cross volunteers are helping the local search and rescue personnel in cleaning storm debris from houses and streets.',
    fieldsStep3CheckboxSectionsNSActionsEPIEWPlaceholder: 'Brief description of the action',

    fieldsStep3CheckboxSectionsFederationActionsEVTEPILabel: 'Actions taken by the IFRC',
    fieldsStep3CheckboxSectionsFederationActionsEWLabel: 'Early Actions Taken by IFRC',
    fieldsStep3CheckboxSectionsFederationActionsEVTEPIDescription: 'Select the activities taken by the IFRC (could be the Regional office, cluster office or country office) and briefly describe.',
    fieldsStep3CheckboxSectionsFederationActionsEPICOVDescription: `In this section you can provide details as to how you have been working with the IFRC in this operation. To help in completing this section some prompting questions are below which are meant to function as a guide only:
  - What role has the IFRC been playing in this operation?
  - What actions has the IFRC taken to support this operation?
  - What are the recent changes in the actions taken by the IFRC since the last field report?
  - Are there any areas where you hope IFRC can support with?`,
    fieldsStep3CheckboxSectionsFederationActionsEWDescription: 'Select the early action activities undertaken by the IFRC and give a brief description',
    fieldsStep3CheckboxSectionsFederationActionsEVTEPIEWPlaceholder: 'Brief description of the action',

    fieldsStep3CheckboxSectionsPNSActionsEVTLabel: 'Actions taken by any other RCRC Movement actors',
    fieldsStep3CheckboxSectionsPNSActionsEPILabel: 'Actions taken by other RCRC Movement',
    fieldsStep3CheckboxSectionsPNSActionsEWLabel: 'Early Action Taken by other RCRC Movement',
    fieldsStep3CheckboxSectionsPNSActionsEVTEPIDescription: 'Select the activities undertaken by any other RCRC Movement actor(s) and briefly describe.',
    fieldsStep3CheckboxSectionsPNSActionsEPICOVDescription: `We understand multiple partners may be present so please highlight the main partners you have worked with since the last field report. Below are some guiding questions to help complete this section. These questions are meant to function as a guide only:
  - Since the last Field Report who has your NS been working with and in what areas of support?
  - Are there any interesting details about these RCRC partners that are not covered in the 3W?
  - Are there any new partnerships that your NS may be working with looking forward?`,
    fieldsStep3CheckboxSectionsPNSActionsEWDescription: 'Select the early action activities undertaken by the RCRC Movement and give a brief description.',
    fieldsStep3CheckboxSectionsPNSActionsEVTEPIEWPlaceholder: 'Brief description of the action',

    fieldsStep3ActionsOthersEVTEPILabel: 'Actions Taken by Others (Governments, UN)',
    fieldsStep3ActionsOthersEWLabel: 'Early Actions Taken by Others (Governments, UN)',
    fieldsStep3ActionsOthersEVTEPIDescription: 'Who else was involved? UN agencies? NGOs? Government? Describe what other actors did. Also mention who the other actors are.',
    fieldsStep3ActionsOthersEPICOVDescription: 'Who else is your National Society working with? UN agencies? NGOs? Government? Since the last field report, outline the main actors your NS has been engaged with. We understand there may be many partners so please just highlight the main ones since the last field report.',
    fieldsStep3ActionsOthersEWDescription: 'List the early action activities undertaken by other actors, mention who the other actors are, and give a brief description.',
    fieldsStep3ActionsNotesPlaceholder: 'If selected any of the activities above, please provide brief details as to how individuals were reached by the activities listed above. We encourage you to consult the previous field report and to note any changes in actions (have new activities started or concluded?). Please highlight any key successes or challenges or unique learnings.',

    fieldsStep3ExternalPartnersLabel: 'External partners',
    fieldsStep3SupportedActivitiesLabel: 'Supported/partnered activities',
    fieldsStep3CombinedLabelExternalSupported: 'External partners / Supported activities',

    fieldsStep3TooltipDescriptionRCRC: 'Number of people who have received RCRC services to prevent COVID-19 transmission or provide healthcare, social, or economic services to reduce the impact of COVID-19. Note one person may receive multiple services.',
    fieldsStep3TooltipDescriptionNS: 'Number of people currently involved in the response (mobilized for this specific response).',
    fieldsStep3TooltipDescriptionVolunteers: 'Number of people currently involved in the response (mobilized for this specific response).',

    fieldsStep4PlannedResponseRowsDREFValueFieldLabel: 'Amount CHF',
    fieldsStep4PlannedResponseRowsDREFEVTEPILabel: 'DREF Requested',
    fieldsStep4PlannedResponseRowsDREFEWLabel: 'DREF',

    fieldsStep4PlannedResponseRowsEmergencyAppealValueFieldLabel: 'Amount CHF',
    fieldsStep4PlannedResponseRowsEmergencyAppealEVTEPIEWLabel: 'Emergency Appeal',

    fieldsStep4PlannedResponseRowsFactValueFieldLabel: 'Number of people',
    fieldsStep4PlannedResponseRowsFactEVTEPIEWLabel: 'Rapid Response Personnel',
    fieldsStep4PlannedResponseRowsFactDescription: 'This is the new name for FACT/RDRT/RIT',

    fieldsStep4PlannedResponseRowsIFRCStaffValueFieldLabel: 'Units',
    fieldsStep4PlannedResponseRowsIFRCStaffEVTEPIEWLabel: 'Emergency Response Units',

    fieldsStep4PlannedResponseRowsForecastBasedActionValueFieldLabel: 'Amount CHF',
    fieldsStep4PlannedResponseRowsForecastBasedActionEWLabel: 'Forecast Based Action',

    fieldsStep4ContactRowsOriginatorLabel: 'Originator',
    fieldsStep4ContactRowsOriginatorEVTEPIEWDesc: 'NS or IFRC Staff completing the Field Report.',

    fieldsStep4ContactRowsNSContactLabel: 'National Society Contact',
    fieldsStep4ContactRowsNSContactEVTEPIDesc: 'The most senior staff in the National Society responsible and knowledgeable about the disaster event.',
    fieldsStep4ContactRowsNSContactEWDesc: 'The most senior staff in the NS responsible and knowledgeable about the risk.',

    fieldsStep4ContactRowsFederationContactLabel: 'IFRC Focal Point for the Emergency',
    fieldsStep4ContactRowsFederationContactEVTEPIDesc: 'IFRC staff who is overall responsible for supporting the NS in its response to the disaster event.',
    fieldsStep4ContactRowsFederationContactEWDesc: 'IFRC staff who is overall responsible for supporting the NS in its response to the anticipated disaster event',

    fieldsStep4ContactRowsMediaContactLabel: 'Media Contact',
    fieldsStep4ContactRowsMediaContactEVTEPIEWDesc: 'An IFRC secretariat media contact in Geneva/Region or Country.',

    fieldReportConstantStatusEarlyWarningLabel: 'Early Warning / Early Action',
    fieldReportConstantStatusEarlyWarningDescription: 'First report for this hazard.',
    fieldReportConstantStatusEventLabel: 'Event',
    fieldReportConstantStatusEventDescription: 'First report for this disaster.',

    fieldReportConstantVisibility: 'Visibility',
    fieldReportConstantVisibilityPublicLabel: 'Public',
    fieldReportConstantVisibilityRCRCMovementLabel: 'RCRC Movement',
    fieldReportConstantVisibilityIFRCSecretariatLabel: 'IFRC Secretariat',
    fieldReportConstantVisibilityPublicTooltipTitle: 'Available to all stakeholders on the GO platform',
    fieldReportConstantVisibilityRCRCMovementTooltipTitle: 'Available to those who have an IFRC GO login across the RCRC Movement',
    fieldReportConstantVisibilityIFRCSecretariatTooltipTitle: 'Available to only those with an active IFRC account',

    fieldReportFormSubmitError: 'Could not submit field report',
    fieldReportFormErrorLabel: 'Error:',
    fieldReportFormLoadDataErrorMessage: 'Failed to load Form Data',
    fieldReportFormErrorInFormMessage: 'There are errors in the form',
    fieldReportFormRedirectMessage: 'Field report updated, redirecting...',
    fieldReportFormItemContextLabel: 'Context',
    fieldReportFormItemSituationLabel: 'Situation',
    fieldReportFormItemRiskAnalysisLabel: 'Risk Analysis',
    fieldReportFormItemActionsLabel: 'Actions',
    fieldReportFormItemEarlyActionsLabel: 'Early Actions',
    fieldReportFormItemResponseLabel: 'Response',

    fieldReportFormStatusLabel: 'Status *',
    fieldReportFormCovidLabel: 'COVID-19 Related Event *',

    fieldReportFormOptionYesLabel: 'Yes',
    fieldReportFormOptionNoLabel: 'No',
    fieldReportFormOptionPlannedLabel: 'Planned',
    fieldReportFormOptionPublishedLabel: 'Yes/Published',

    fieldReportFormTitleSecondaryLabel: 'Add Title',
    fieldReportFormTitleSelectLabel: 'Please check for, and link to an existing emergency if available',
    fieldReportFormTitleInputPlaceholder: 'Example: Malawi - Central Region: Floods 03/2019',
    fieldReportFormTitleSelectPlaceholder: 'Click here to link to an existing hazard alert (if one exists)',

    fieldReportFormCountrySelectPlaceholder: 'Select a country',
    fieldReportFormRegionSelectPlaceholder: 'Select Provinces / Regions',

    fieldReportFormEPISourceOfFiguresLabel: 'Source (of figures)',

    fieldReportFormSourceDetailsLabel: 'Source Details',
    fieldReportFormSourceDetailsPlaceholder: 'Add details for data with sources marked as Other above.',
    fieldReportFormSourceDetailsEPIPlaceholder: 'Add resource url for situation report',
    fieldReportFormSourceDetailsDescription: 'Add details for sources above (if applicable)',

    fieldReportFormActionDataLoadingMessage: 'Loading Actions Data...',
    fieldReportFormActionTakenTitle: 'Actions taken',

    fieldReportFormInformationBulletinLabel: 'Information Bulletin',
    fieldReportFormInformationBulletinDescription: 'Indicate if an Information Bulletin was published, is planned or if no Information Bulletin will be issued for this operation/disaster/hazard.',

    fieldReportFormOthersActionsPlaceholder: 'Brief description of the action',
    fieldReportFormResponseTitle: 'Planned Interventions',
    fieldReportFormResponseTitleEVT: 'Planned Response',
    fieldReportFormResponseLabel: 'Planned International Response',
    fieldReportFormResponseDescription: 'Indicate status of global and regional tools.',
    fieldReportFormContactsTitle: 'Contacts',

    fieldReportFormVisibilityLabel: 'This field report is visible to',
    fieldReportFormPageTitle: 'IFRC Go - New Field Report',

    breadCrumbLogin: 'Login',
    breadCrumbResources: 'Resources',
    breadCrumbAccount: 'Account',
    breadCrumbHome: 'Home',
    breadCrumbSurge: 'Surge',
    breadCrumbPERForm: 'PER Form',
    breadCrumbEmergency: 'Emergency',
    breadCrumbEmergencies: 'Emergencies',
    breadCrumbFieldReport: 'Field Report',
    breadCrumbPreparedness: 'Preparedness',
    breadCrumbRegister: 'Register',
    breadCrumbNewFieldReport: 'New Field Report',

    countriesEmergencyLinkTooltip: 'View Emergency',
    countriesActiveLabel: 'Active',
    countriesInactiveLabel: 'Inactive',
    countriesAllOperationExportLink: 'View All Operations For {name}',
    countriesStatisticsTitle: 'Statistics',

    emergencyPageTitleStatic: 'IFRC Go - Emergency',
    emergencyPageLabel: 'Emergency',
    emergencyPageTitle: 'IFRC Go - {name}',
    emergencyTabDetails: 'Emergency Details',
    emergencyTabReports: 'Reports/Documents',
    emergencyTabSurge: 'Surge',
    emergencyKeyFiguresTitle: 'Key Figures',
    emergencyOverviewTitle: 'Overview',
    emergencyOverviewBoxTitle: 'Emergency Overview',
    emergencyDisasterType: 'Disaster Type',
    emergencyDisasterCat: 'Disaster Categorization',
    emergencyGovtReqIntlAsst: 'Government Requests International Assistance',
    emergencyNSReqIntlAsst: 'NS Requests International Assistance',
    emergencyDREF: 'DREF',
    emergencyRapidResponsePersonnel: 'Rapid Response Personnel',
    emergencyAffectedRegions: 'Affected Provinces',
    emergencySituationalOverviewTitle: 'Situational Overview',
    emergencyAlertsTitle: 'Open Surge Alerts',
    emergencyPersonnelTitle: 'Personnel',
    emergencyERUTitle: 'ERUs',
    emergencyFieldReportsTitle: 'Field Reports',
    emergencyFieldReportsWithCountTitle: 'Reports ({count})',
    emergencyAppealDocumentsTitle: 'Appeal Documents',
    emergencyAppealDocHeaderDate: 'Date',
    emergencyAppealDocHeaderCode: 'Code',
    emergencyAppealDocHeaderName: 'Name',
    emergencyResponseDocumentsTitle: 'Response Documents',
    emergencyReportSearchPlaceholder: 'Search',
    emergencyDocumentsNoResultsMessage: 'No results found for search',
    emergencyContactsIFRC: 'IFRC',
    emergencyContactsNS: 'National Societies',
    emergencyContactsTitle: 'Contacts',
    emergencyContactTitle: 'Contact',
    emergencyContactEmptyMessage: 'No current contacts',
    emergencyContactsTableHeaderName: 'Name',
    emergencyContactsTableHeaderTitle: 'Title',
    emergencyContactsTableHeaderType: 'Type',
    emergencyContactsTableHeaderContact: 'Contact',
    emergencyFieldReportTableHeaderDate: 'Date',
    emergencyFieldReportTableHeaderName: 'Name',
    emergencyFieldReportTableHeaderCountries: 'Countries',
    emergencyFieldReportTableHeaderRegions: 'Regions',
    emergencyFieldReportLinkTitle: 'View Field Report',
    emergencyTopOverviewSectionTitle: 'Emergency Overview',
    emergencyPotentiallyAffectedLabel: 'Potentially Affected',
    emergencyHighestRiskLabel: 'Highest Risk',
    emergencyAffectedPopulationCentresLabel: 'Affected Population Centres',
    emergencyAssistedByGovernmentLabel: 'Number of People Assisted by Government - Early Action',
    emergencyAssistedByRCRCLabel: 'Number of People Assisted by RCRC Movement - Early Action',
    emergencyCasesLabel: 'Cases',
    emergencySuspectedCasesLabel: 'Suspected Cases',
    emergencyProbableCasesLabel: 'Probable Cases',
    emergencyConfirmedCasesLabel: 'ConfirmedCases',
    emergencyDeadLabel: 'Dead',
    emergencyAffectedLabel: 'Affected',
    emergencyInjuredLabel: 'Injured',
    emergencyMissingLabel: 'Missing',
    emergencyDisplacedLabel: 'Displaced',
    emergencyAssistedLabel: 'Assisted',
    emergencyLocalStaffLabel: 'Local staff',
    emergencyVolunteersLabel: 'Volunteers',
    emergencyDelegatesLabel: 'Delegates',
    emergencyPeopleTargetedLabel: 'People Targeted',
    emergencyFundingRequirementsLabel: 'Funding Requirements (CHF)',
    emergencyFundingLabel: 'Funding (CHF)',
    emergencyNoDocumentsMessage: 'No documents added',
    emergencySourceFigure: 'Source: {source}',
    emergencyActionSubscribeLabel: 'Follow',
    emergencyActionUnsubscribeLabel: 'Unfollow',
    emergencyActionEditEventLabel: 'Edit Event',
    emergencyActionCreateThreeWActivityLabel: 'Create 3W Activity',

    passwordChangePageTitle: 'IFRC Go - Change Password',
    passwordChangeHeading: 'Change my password',

    perPageTitle: 'IFRC Go - PER',
    countryOverviewTab: 'Country Profile',

    countryProfileKeyDocumentsTitle: 'Key Documents',
    countryProfileExternalSourcesTitle: 'External Sources',
    countryProfileDateRange: 'Date Range',
    countryProfileSearchPlaceholder: 'Search',

    dateFilterHeaderTooltip: 'Filter by {title}',
    dateFilterHeaderFromLabel: 'From',
    dateFilterHeaderToLabel: 'To',
    dateFilterHeaderApplyButtonLabel: 'Apply',

    alertsTableAllLabel: 'All',

    emergenciesDashAllEmergenciesLinkLabel: 'View All Emergencies',

    newPasswordErrorMessage: 'Error: {message}',
    newPasswordSuccessMessage: 'Success! Password changed, redirecting...',
    newPasswordVerifyInputLabel: 'Verify old password',

    userMenuPopupHeading: 'Hello {firstName} {lastName}',

    monthNameJanuary: 'January',
    monthNameFebruary: 'February',
    monthNameMarch: 'March',
    monthNameApril: 'April',
    monthNameMay: 'May',
    monthNameJune: 'June',
    monthNameJuly: 'July',
    monthNameAugust: 'August',
    monthNameSeptember: 'September',
    monthNameOctober: 'October',
    monthNameNovember: 'November',
    monthNameDecember: 'December',

    monthNameShortJan: 'Jan',
    monthNameShortFeb: 'Feb',
    monthNameShortMar: 'Mar',
    monthNameShortApr: 'Apr',
    monthNameShortMay: 'May',
    monthNameShortJun: 'Jun',
    monthNameShortJul: 'Jul',
    monthNameShortAug: 'Aug',
    monthNameShortSep: 'Sep',
    monthNameShortOct: 'Oct',
    monthNameShortNov: 'Nov',
    monthNameShortDec: 'Dec',

    preparednessOverviewContactLabel: 'Contact PER team',
    preparednessPhaseOutcomesUploadLinkLabel: 'Upload',

    formElementErrorRequiredMessage: 'Please fill in the field.',
    formElementErrorDependenciesMessage: 'Please fill in the field.',
    formElementErrorConstMessage: 'Passwords must match.',

    preparednessFactoryNSGraphDataAllCountriesLabel: 'All countries',
    preparednessFactoryNSGraphDataEngagedCountriesLabel: 'Engaged countries',

    privateRouteNotAuthenticatedMessage: 'Please log in to view this page',

    displayTableFilterHeaderOptionTooltipText: 'Filter by {title} - {label}',
    displayTableFilterHeaderLabelTooltipText: 'Filter by {title}',
    displayTableSortHeaderTooltip: 'Sort by {title}',

    exportButtonContainerErrorMessage: 'Error: Could not export data',
    exportButtonContainerExportingMessage: 'Info: Exporting...',

    accountReportListLastUpdated: 'Last Updated: {on}',
    accountProfileFormSubmitButtonTooltip: 'Save',

    ifrcLinkText: '{countryName} on IFRC.org',
    reliefWebLinkText: '{countryName} on reliefweb.int',
    homePageLinkText: '{countryName} RC Homepage',

    noDataMessage: 'No data to display.',

    deploymentsPageTitleSurge: 'Surge',
    deploymentsOverviewError: 'Error loading data',
    deploymentsRapidResponse: 'Rapid Response',

    emergencySourceMessage: 'Source: {link}',
    emergencyFieldReportStatsHeading: 'Key Figures',

    recoverAccountErrorMessage: 'Error: {message}',
    recoverAccountSuccessMessage: 'We have sent an email to your inbox. Redirecting...',

    recoverAccountInvalidEmailMessage: 'Please enter a valid email',

    recoverUsernameSuccessMessage: 'If the given email address exists here, you will find an email in your mailbox...',
    recoverUsernameInvalidEmailMessage: 'Please enter a valid email',

    registerCreateUserDefaultErrorMessage: 'Could not create user',
    registerErrorMessage: 'Error: {message}',
    registerSuccessMessage: 'Success! Verification email sent, redirecting...',

    resendValidationErrorMessage: 'Error: {message}',
    resendValidationSuccessMessage: 'We have sent an email to your inbox. Redirecting...',

    tableNounOperations: 'Operations',
    tableNounDREFs: 'DREFs',
    tableNounAppeals: 'Appeals',

    fieldReportLastUpdatedBy: 'Last updated by {user} on {date}',
    fieldReportLastUpdatedOn: 'Last updated on {date}',

    searchSelectTypeToSearch: 'Type to search',
    searchSelectNoOptionsAvailable: 'No options available',
};
export default texts;